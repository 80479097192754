import { useNavigate } from "react-router-dom";
import DollarCoin from "../../icons/dollar-coin.svg";
import XendIcon from "../../icons/xend-icon.png";
import ProjectBg from "../../icons/project-bg2.png";
import { ReactComponent as Tick } from "../../icons/tick-star.svg";
import { formatNumber } from "../../utils/formatNumber";

export const SingleJoinedProject = ({ airdrop }) => {
  const navigate = useNavigate();

  const goToDetails = (joined, airdrop_id) => {
    if (joined) {
      navigate(`/project-details/${airdrop_id}`);
    }
  };

  return (
    <div
      key={airdrop?.airdrop?.airdrop_id}
      className="p-4 bg-cover bg-no-repeat rounded-[10px] cursor-pointer"
      style={{
        backgroundImage: `url('${airdrop?.airdrop?.image || ProjectBg}')`,
        backgroundColor: "#000000",
        boxShadow: "0px 5px 0px 0px #CACACA40",
      }}
      onClick={() => goToDetails(airdrop?.joined, airdrop?.airdrop?.airdrop_id)}
    >
      <div className="flex justify-between items-center w-full mb-4 ">
        <div className="flex items-center gap-2">
          <div className="relative w-fit">
            <img
              src={airdrop?.airdrop?.logo || XendIcon}
              alt="logo"
              className="w-[1.875rem] h-[1.875rem] rounded-[0.25rem]"
            />
            <Tick className="w-5 h-5 absolute -bottom-1 -right-2" />
          </div>

          <p className="font-semibold text-white text-[0.875rem]">
            {airdrop?.airdrop?.name}
          </p>
        </div>

        <div className="flex items-center gap-2">
          <div className="text-[#83C60D] first-letter:uppercase text-[0.75rem] h-[30px] w-[70px] rounded-[60px] bg-[#EEFFD1] flex items-center justify-center">
            <span className="first-letter:uppercase">
              {airdrop?.airdrop?.status}
            </span>
          </div>
        </div>
      </div>

      <div className="max-w-[230px] mb-4">
        <p className="font-semibold text-white text-[0.875rem]">
        {(airdrop?.airdrop?.description)}
        </p>
      </div>

      {/* Footer area */}
      <div className="flex items-end justify-between gap-2">
        <div className="flex items-center gap-2">
          <div>
            <p className="text-[0.625rem] text-[#808080] mb-1">Reward pool</p>
            <div className="flex gap-1 items-center bg-black rounded-[1rem] py-1 px-2 min-h-[30px]">
              <img
                src={airdrop?.airdrop?.logo || XendIcon}
                alt="logo"
                className="w-[1rem] h-[1rem] rounded-[1rem]"
              />
              <span className="text-[0.625rem] text-white">
                {formatNumber(airdrop?.airdrop?.rewardPoolAmount ?? 0)}{" "}
                {airdrop?.airdrop?.symbol}
              </span>
            </div>
          </div>

          <div>
            <p className="text-[0.625rem] text-[#808080] mb-1">Loot per hour</p>
            <div className="flex gap-1 items-center bg-black rounded-[1rem] py-1 px-2 min-h-[30px]">
              <img
                src={DollarCoin}
                alt="coin"
                className="w-[1rem] h-[1rem] rounded-[1rem]"
              />
              <span className="text-[0.625rem] text-white">
                + {formatNumber(airdrop?.airdrop?.profitPerHour ?? 0)}{" "}
              </span>
            </div>
          </div>
        </div>
        <button
          onClick={() => {}}
          disabled={false}
          className="bg-[#83c60d] h-[30px] text-[#0D0F18] text-[14px] font-semibold rounded-[30px] px-4"
          // style={}
        >
          <div className={`sub-button first-letter:uppercase`}>
            Open Airdrop
          </div>
        </button>
      </div>
    </div>
  );
};
