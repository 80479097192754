import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DollarCoin from "../../icons/dollar-coin.svg";
import UserIcon from "../../icons/user.svg";
import { ExpandLeftIcon } from "../../icons";
import { ReactComponent as Raid } from "../../icons/raid.svg";
import { ReactComponent as Share } from "../../icons/share.svg";
import { ReactComponent as Tick } from "../../icons/tick-star.svg";
import ProjectBg from "../../icons/project-bg3.png";
import { formatNumber } from "../../utils/formatNumber";
import moment from "moment";
import useCopyClickHandler from "../../utils/InviteFriend";
import { config } from "../../config";
import { context } from "../../App";

export const ProjectName = ({ data }) => {
  const navigate = useNavigate();
  const { telegramId } = useContext(context);
  const handleCopyClick = useCopyClickHandler();
  const referralLink = `${config.referralLinkURL}=${telegramId}`;

  const goToDetails = (airdrop_id) => {
    navigate(`/raid-leaderboard/${airdrop_id}`);
  };

  const totalUsers = data?.airdrop?.totalUsersCompleted ?? 0;
  const rewardPool = data?.airdrop?.rewardPool ?? 0;

  const progressPercentage =
    rewardPool > 0 ? (totalUsers / rewardPool) * 100 : 0;

  return (
    <>
      {data && data?.airdrop && (
        <>
          <div
            className="p-4 bg-[#151827] rounded-[10px] bg-cover bg-center"
            style={{
              backgroundImage: `url('${ProjectBg}')`,
              boxShadow: "0px 5px 0px 0px #CACACA40",
            }}
          >
            <div className="flex justify-between items-center w-full mb-4">
              <div className="flex items-center gap-2">
                <div className="relative w-fit">
                  <img
                    src={data?.airdrop?.logo}
                    alt="logo"
                    className="w-[1.875rem] h-[1.875rem] rounded-[0.25rem]"
                  />
                  <Tick className="w-5 h-5 absolute -bottom-1 -right-2" />
                </div>

                <p className="font-semibold text-white text-[0.875rem]">
                  {data?.airdrop?.name}
                </p>
              </div>

              <div className="flex items-center gap-2">
                <button
                  onClick={() => {}}
                  disabled={false}
                  className="bg-[#83c60d] h-[35px] text-[#0D0F18] text-[14px] font-semibold rounded-[35px] px-4"
                >
                  <div
                    onClick={() =>
                      handleCopyClick({
                        text:
                          `Play with me, become a Grandmaster Raider and get a token airdrop!
    20k Coins as a first-time referral bonus 🔥 35k Coins referral bonus if you have Telegram Premium` ||
                          "",
                        referralLink,
                      })
                    }
                    className="flex justify-center items-center gap-1"
                  >
                    <Share className="w-5 h-5" />
                    <span>Share</span>
                  </div>
                </button>
              </div>
            </div>

            <div className="mb-5 mt-1">
              <p className="font-semibold text-white text-[0.875rem]">
                {data?.airdrop?.description}
              </p>
            </div>

            {/* Footer area */}
            <div className="flex items-center gap-2">
              <div>
                <p className="text-[0.625rem] text-[#848AA0] mb-1">
                  Reward pool
                </p>
                <div className="flex gap-1 items-center bg-black rounded-[1rem] py-1 px-3 h-[30px]">
                  <img
                    src={data?.airdrop?.logo}
                    alt="logo"
                    className="w-[1rem] h-[1rem] rounded-[1rem]"
                  />
                  <span className="text-[0.625rem] text-white">
                    {formatNumber(data?.airdrop?.rewardPoolAmount ?? 0)}{" "}
                    {data?.airdrop?.symbol}
                  </span>
                </div>
              </div>

              <div>
                <p className="text-[0.625rem] text-[#848AA0] mb-1">
                  Loot per hour
                </p>
                <div className="flex gap-1 items-center bg-black rounded-[1rem] py-1 px-3 h-[30px]">
                  <img
                    src={DollarCoin}
                    alt="coin"
                    className="w-[1rem] h-[1rem] rounded-[1rem]"
                  />
                  <span className="text-[0.625rem] text-white">
                    +{(data?.airdrop?.profitPerHour ?? 0).toLocaleString()}
                  </span>
                </div>
              </div>

              <div>
                <p className="text-[0.625rem] text-[#848AA0] mb-1">Duration</p>
                <div className="flex gap-1 items-center bg-black rounded-[1rem] py-1 px-3 h-[30px]">
                  <span className="text-[0.625rem] text-white">
                    {moment(data?.airdrop?.startDate).format("DD MMM")} -{" "}
                    {moment(data?.airdrop?.endDate).format("ll")}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2 mt-2">
              <div>
                <p className="text-[0.625rem] text-[#848AA0] mb-1">
                  Active raiders
                </p>
                <div className="flex gap-1 items-center bg-black rounded-[1rem] py-1 px-3 h-[30px]">
                  <img
                    src={UserIcon}
                    alt="coin"
                    className="w-[1rem] h-[1rem] rounded-[1rem]"
                  />
                  <span className="text-[0.625rem] text-white">
                    {data?.airdrop?.totalUsers.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>

            <div className="relative flex justify-center w-full mx-auto mt-10 mb-4">
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>

              <button
                type="button"
                onClick={() => goToDetails(data?.airdrop?.airdrop_id)}
                className="absolute left-0 top-[-120%] text-white flex items-center"
              >
                <Raid />
                <span className="text-[0.675rem] text-white">
                  Raid completion progress
                </span>
                <ExpandLeftIcon
                  style={{ cursor: "pointer" }}
                  className="w-[1.25rem] h-[1.25rem]"
                />
              </button>

              <div className="absolute right-0 top-[-120%] text-white">
                <span className="text-[0.675rem] text-white">
                  {(data?.airdrop?.totalUsersCompleted ?? 0).toLocaleString()}
                </span>
              </div>
            </div>

            <div className="bg-[#613700B8] rounded-md px-4 py-3">
              <p className="text-[#FFBA5A] text-[0.75rem] text-center">
                Raid and complete all quest to get {data?.airdrop?.symbol}{" "}
                airdrop
              </p>
              {/* <p className="text-[#FFBA5A] text-[0.75rem] text-center">
                First {(data?.airdrop?.rewardPool ?? 0).toLocaleString()}{" "}
                raiders to complete all quests get {data?.airdrop?.symbol}{" "}
                airdrop
              </p> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};
