import React, { act, useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as Lock } from "../../icons/lock_fill.svg";
import { ReactComponent as Tick } from "../../icons/tick-circle.svg";
import { AirdropConnect } from "../../services/airdrop.service";
import { context } from "../../App";
import { logConsole } from "../../utils/useConsole";
import LockedAirdropQuest from "../modals/AirdropQuest/LockedAirdropQuest";
import UnlockedAirdropQuest from "../modals/AirdropQuest/UnlockedAirdropQuest";
import moment from "moment";
import { AirdropContext } from "../../pages/ProjectDetails";
import useCopyClickHandler from "../../utils/InviteFriend";
import { config } from "../../config";
import { useNavigate } from "react-router-dom";

export const splitString = (str) => {
  let arr = [];
  if (str?.includes(".")) {
    arr = str.split(".");
  } else {
    arr = [str];
  }
  return arr;
};

export const AirdropQuest = ({ data }) => {
  const airdrop = new AirdropConnect();
  const navigate = useNavigate();
  const { telegramId, triggerNotification, setShowProcessingModal } =
    useContext(context);
  const { getAirdrop, currentAirdrop } = useContext(AirdropContext);
  const [questData, setQuestData] = useState([]);
  const initialQuestDataRef = useRef([]);

  useEffect(() => {
    if (currentAirdrop?.quest) {
      if (initialQuestDataRef.current.length === 0) {
        // This is the first time we're setting the quest data
        initialQuestDataRef.current = currentAirdrop.quest;
        setQuestData(currentAirdrop.quest);
      } else {
        // We're updating the quest data, so we need to preserve useLink states
        const updatedQuestData = currentAirdrop.quest.map((newQuest, questIndex) => ({
          ...newQuest,
          questTask: newQuest.questTask.map((newTask, taskIndex) => ({
            ...newTask,
            useLink: initialQuestDataRef.current[questIndex]?.questTask[taskIndex]?.useLink || newTask.useLink || false
          }))
        }));
        setQuestData(updatedQuestData);
      }
    }
  }, [currentAirdrop]);

  const [showLockedQuestModal, setShowLockedQuestModal] = useState(false);
  const [showUnlockedQuestModal, setShowUnlockedQuestModal] = useState(false);
  const [selectedQuest, setSelectedQuest] = useState({});
  const handleCopyClick = useCopyClickHandler();
  const referralLink = `${config.referralLinkURL}=${telegramId}`;

  const calculateTimeLeft = (doneTimer) => {
    const now = moment();
    const then = moment(doneTimer);
    const duration = moment.duration(then.diff(now));

    return {
      hours: Math.floor(duration.asHours()),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  };

  const formatTimeLeft = (timeLeft) => {
    if (timeLeft.seconds <= 0) {
      return null;
    }

    const hoursString = timeLeft.hours > 0 ? `${timeLeft.hours}hr ` : "";
    const minutesString = timeLeft.minutes > 0 ? `${timeLeft.minutes}m ` : "";
    const secondsString = timeLeft.seconds > 0 ? `${timeLeft.seconds}s` : "";
    return `${hoursString}${minutesString}${secondsString}`.trim();
  };

  // useEffect(() => {
  //   setQuestData(currentAirdrop?.quest);
  // }, [currentAirdrop, questData]);

  const goToLink = ({ link, action }) => {
    const text = `Play with me, become a Grandmaster Raider and get a token airdrop!
    20k Coins as a first-time referral bonus 🔥 35k Coins referral bonus if you have Telegram Premium`;
    if (action === "REFERRAL") {
      handleCopyClick({ text: text || "", referralLink });
    } else if (action === "TON_CONNECT") {
      navigate("/airdrops");
    } else if (action === "CLAIM") {
      return;
    } else if (["INSTAGRAM", "TWITTER", "TELEGRAM"].includes(action)) {
      window.open(link, "_blank");
    }
  };

  const checkIfCountEnbaled = (type) => {
    const enabledTypes = [
      "TELEGRAM",
      "CLAIM REWARD",
      "TON_CONNECT",
      "INSTAGRAM",
      "TWITTER",
    ];
    return enabledTypes.includes(type);
  };

  const checkUseOfFollow = (type) => {
    const enabledTypes = ["TELEGRAM", "INSTAGRAM", "TWITTER"];
    return enabledTypes.includes(type);
  };



  const completeQuest = async (item, quest, itemIndex, questIndex) => {
    setShowProcessingModal(true);
    try {
      if (!questData[itemIndex].questTask[questIndex].useLink && checkUseOfFollow(item?.action)) {
        goToLink({ link: item?.link, action: item?.action });
        
        const newItems = [...questData];
        newItems[itemIndex].questTask[questIndex].useLink = true;
        
        // Update both questData and initialQuestDataRef
        initialQuestDataRef.current = newItems;
        setQuestData(newItems);
      }  else {
        const data_ = {
          telegramId,
          airdrop_id: quest?.airdrop_id,
          quest_id: item?.quest_task_id,
          questLevel: quest?.level,
        };
        const { data: response } = await airdrop.completeQuest(data_);
        if (response) {
          logConsole({ data_: response }, true);
          triggerNotification("success", "Task completed");
          await getAirdrop(true);
          handleQuestClick(itemIndex, questIndex);
        }
      }
    } catch (error) {
      const errorMessage = airdrop.handleTheError(error);
      await getAirdrop(true);
      triggerNotification("info", errorMessage);
    }
    setShowProcessingModal(false);
  };

  const handleQuestClick = (itemIndex, questIndex) => {
    const newItems = [...questData];

    if (questIndex + 1 < newItems[itemIndex].questTask?.length) {
      newItems[itemIndex].questTask[questIndex + 1].isOpen = true;
      newItems[itemIndex].questTask[questIndex].completed = true;
    } else if (itemIndex + 1 < newItems.length) {
      newItems[itemIndex].questTask[questIndex].isOpen = true;
      newItems[itemIndex].questTask[questIndex].completed = true;

      const checkifAllQuestTaskIsCompleted = ({ quest, itemIndex }) => {
        if (itemIndex <= 1) {
          return true;
        }
        return quest?.questTask.every((task) => task.completed == false);
      };

      const allchecked = checkifAllQuestTaskIsCompleted({
        quest: newItems[itemIndex + 1],
        itemIndex,
      });

      if (allchecked) {
        newItems[itemIndex + 1].isOpen = true;
      } else {
        newItems[itemIndex + 1].isOpen = false;
        triggerNotification("info", `Complete quest ${itemIndex}`);
      }
    }

    setQuestData(newItems);
  };

  return (
    <div className="py-4">
      <LockedAirdropQuest
        open={showLockedQuestModal}
        setOpen={setShowLockedQuestModal}
        data={selectedQuest}
        completeQuest={completeQuest}
      />

      <UnlockedAirdropQuest
        open={showUnlockedQuestModal}
        setOpen={setShowUnlockedQuestModal}
        data={selectedQuest}
        completeQuest={completeQuest}
      />

      <ul role="list" className="-mb-8">
        {questData && questData?.length ? (
          questData
            ?.sort((a, b) => a.level - b.level)
            .map((activityItem, activityItemIdx) => (
              <li key={activityItem.quest_id}>
                <div className="relative pb-3">
                  <div className="relative flex items-start space-x-3">
                    <>
                      <div
                        className={`relative z-10 px-1 ${
                          activityItem?.isOpen
                            ? "mix-blend-normal"
                            : "mix-blend-luminosity"
                        }`}
                      >
                        <Tick className={`h-5 w-5`} />
                      </div>
                      <div className="w-full">
                        <div className="min-w-0 w-full flex justify-between items-center pb-3">
                          <div
                            className={`text-xs  ${
                              activityItem.isOpen
                                ? "text-[#83C60D]"
                                : "text-[#4E5367]"
                            } flex gap-2 items-center`}
                          >
                            <p className="font-semibold">{`Airdrop Quest ${
                              activityItemIdx + 1 >= questData?.length
                                ? "Final"
                                : activityItemIdx + 1
                            }`}</p>
                            {!activityItem?.isOpen && (
                              <Lock className="w-4 h-4" />
                            )}
                          </div>

                          {activityItem?.msg !== "All conditions met" && (
                            <div className="text-[0.6rem] text-[#F28A00]">
                              <p className="font-semibold">
                                {activityItem?.msg}
                              </p>
                            </div>
                          )}
                        </div>

                        {activityItem?.questTask &&
                          activityItem?.questTask?.map((item, index) => (
                            <div key={index} className="flex items-center mb-3">
                              {item !== activityItem?.questTask?.length - 1 ? (
                                <span
                                  className={`absolute z-0 top-5 left-[0.85rem] -ml-px h-full border-l-2 border-[#212942] 
                              ${
                                activityItem?.isOpen
                                  ? "border-solid"
                                  : "border-dashed"
                              }`}
                                  aria-hidden="true"
                                />
                              ) : null}

                              <div
                                className={`absolute z-10 left-0 px-1 ${
                                  item?.completed || activityItem?.isOpen
                                    ? "mix-blend-normal"
                                    : "mix-blend-luminosity"
                                }`}
                              >
                                <Tick className={`h-5 w-5`} />{" "}
                              </div>

                              <div className="bg-[#151827] cursor-pointer rounded-lg w-full flex gap-2 justify-between items-center py-2 px-3">
                                <div
                                  onClick={() =>
                                    activityItem?.isOpen &&
                                    goToLink({
                                      link: item?.link,
                                      action: item?.action,
                                    })
                                  }
                                  className={`text-sm ${
                                    activityItem?.isOpen
                                      ? "text-white"
                                      : "text-[#4E5367]"
                                  }`}
                                >
                                  <p className="font-semibold">
                                    {splitString(item?.text).length > 1
                                      ? splitString(item?.text).map(
                                          (text, idx) => (
                                            <div
                                              key={idx}
                                              className="flex flex-row gap-1 mb-2"
                                            >
                                              <p className="text-3xl font-bold h-fit">
                                                .
                                              </p>
                                              <p>{text}</p>
                                            </div>
                                          )
                                        )
                                      : splitString(item?.text)[0]}
                                  </p>
                                </div>
                                <button
                                  onClick={
                                    activityItem?.isOpen
                                      ? () => {
                                          setSelectedQuest({
                                            ...item,
                                            title: `Airdrop Quest ${
                                              activityItemIdx + 1
                                            }`,
                                            item,
                                            activityItem,
                                            activityItemIdx,
                                            index,
                                          });
                                          setShowUnlockedQuestModal(true);
                                        }
                                      : null
                                  }
                                  disabled={false}
                                  className={`${
                                    activityItem?.isOpen
                                      ? "bg-[#83c60d]"
                                      : "bg-[#212942]"
                                  }  h-[35px] text-[14px] font-semibold rounded-[35px] min-w-[100px] px-3`}
                                >
                                  <div
                                    className={`${
                                      activityItem.isOpen
                                        ? "text-[#0D0F18]"
                                        : "text-[#848AA0]"
                                    }`}
                                  >
                                    {activityItem.isOpen ? (
                                      item.completed ? (
                                        "Completed"
                                      ) : item?.doneTimer == null ||
                                        formatTimeLeft(
                                          calculateTimeLeft(item?.doneTimer)
                                        ) == null ? (
                                        item?.btn
                                      ) : (
                                        formatTimeLeft(
                                          calculateTimeLeft(item?.doneTimer)
                                        )
                                      )
                                    ) : (
                                      <span
                                        className="flex gap-1 items-center"
                                        onClick={() => {
                                          setSelectedQuest({
                                            ...item,
                                            title: `Airdrop Quest ${
                                              activityItemIdx + 1
                                            }`,
                                            condition: activityItem?.msg,
                                          });
                                          setShowLockedQuestModal(true);
                                        }}
                                      >
                                        Locked <Lock className="w-4 h-4" />
                                      </span>
                                    )}
                                  </div>
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  </div>
                </div>
              </li>
            ))
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
};
